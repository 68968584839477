.cam-timestamp {
  height: 1.875em;
  padding: 0 !important;
  margin: 0 1em !important;
}
.col.button {
  padding-left: 1% !important;
  padding-right: 1% !important;
  padding-top: 1% !important;
  padding-bottom: 1% !important;
}
/* .btn-timestamp {
  padding: .348rem .70rem
} */
.btn-timestamp {
  padding: 0.85% 0;
}
.image-container {
  text-align: center;
  color: white;
}
.cam-image {
  width: 100%;
}
.carousel-item {
  -webkit-transition: 0.2s ease-in-out left;
  -moz-transition: 0.2s ease-in-out left;
  -o-transition: 0.2s ease-in-out left;
  transition: 0.2s ease-in-out left;
}
.carousel-indicators li {
  border-radius: 50% !important;
  width: 0.5em;
  height: 0.5em;
  margin: 0px 10px -10px 10px;
  border: solid 2px transparent;
}
.carousel-indicators .active {
  border: solid 2px black;
}

.social-icon {
  margin-top: -1% !important;
  width: 2em;
  height: 2em;
  margin: 0 1.5em 0 1.5em;
  cursor: pointer;
}

.darken {
  filter: brightness(50%);
}
.label-W.one {
  position: absolute;
  top: 1.5em;
  right: 20%;
}
.label-W.two {
  position: absolute;
  bottom: 1.6em;
  right: 1em;
}
.label-T.one {
  position: absolute;
  bottom: 8em;
  left: 3%;
}
.label-T.two {
  position: absolute;
  top: 10%;
  right: .5em;
}

.badge {
  font-size: 80%;
}

.badge.badge-light img {
  padding: 0 .3em .1em 0;
}

.d-flex .spinner-border {
  z-index: 1;
  margin: -1em 0 0 -1em;
  position: absolute;
  top: 50%;
  left: 50%;
}
.hidden {
  display: none !important;
}
.table th {
  font-weight: normal;
}
.table {
  margin: 0 !important;
}
.btn-xl {
  font-size: 250% !important;
}
.btn-ddmm {
  font-size: .65em;
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -2% !important;
}
.btn-a2hs {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: #0a61fe;
}
a {
  color: #0a61fe;
  text-decoration: none !important;
}
.overlay {
  display: block;
  content:"CAM DOWN";
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color: rgba(0,0,0,0.7);
}
.overlay-text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
.lazyImage {
  position: relative;
  height: 0;
}
.lazyImage__img {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.carousel-item-container {
  width: 100%;
  min-height: 20.67em;
  height: 20.67em;
}
.apexcharts-data-labels > text {
  font-size: .9rem;
}
.clickable {
  cursor: pointer;
}
.modal-footer {
  border: none;
}

.chatbro_header_rotare_to_bottom {
  all: unset !important;
}
.chatbro_minimized_chat{
  position: fixed !important;
  top: 10px !important;
  right: 20px !important;
  z-index: 1 !important;
}

/* @supports (-webkit-overflow-scrolling: touch) {
  .image-container img {
    height: 200px !important;
  }
} */
@media (max-width: 720px) {
  .spinner-border-sm {
    width: 1em;
    height: 1em;
  }
  .one,
  .two {
    font-size: 0.8em !important;
  }
  .cam-timestamp {
    margin-top: 1vh !important;
  }
  .carousel-item-container {
    width: 100%;
    min-height: 15.75em;
    height: 15.75em;
  }
  .apexcharts-data-labels > text {
    font-size: .6rem;
  }
}

@media (max-width: 414px) {
  button span {
    padding: 0;
    font-size: 5vw;
  }
  .apexcharts-data-labels > text {
    font-size: .475rem;
  }
}

@media (max-width: 320px) {
  button span {
    padding: 0;
    font-size: 5vw;
  }
  .btn-ddmm {
    font-size: .5em;
  }
}

@media (min-width: 720px) {
  body,
  button span {
    font-size: 170%;
  }
  .spinner-border-sm {
    width: 1em;
    height: 1em;
  }
  .cam-timestamp {
    margin-bottom: 1vh !important;
  }
}
@media (min-width: 1024px) {
  body,
  button span {
    font-size: 100%;
  }
  .col-lg-6 {
    padding: 0 1em !important;
  }
  .cam-timestamp {
    margin-bottom: 0 !important;
    margin-top: 1vh !important;
  }
  .apexcharts-data-labels > text {
    font-size: .7rem;
  }
}
@media (min-width: 1280px) {
  body,
  button span {
    font-size: 120%;
  }
  .col-lg-6 {
    padding: 0 1em !important;
  }
  .apexcharts-data-labels > text {
    font-size: .8rem;
  }
}
@media (min-width: 1366px) {
  body,
  button span {
    font-size: 140%;
  }
  .col-lg-6 {
    padding: 0 1em !important;
  }
  .cam-timestamp {
    margin-top: 0 !important;
  }
}
@media (min-width: 1600px) {
  .apexcharts-data-labels > text {
    font-size: .9rem;
  }
}
@media (min-width: 1920px) {
  body,
  button span {
    font-size: 160%;
  }
  .col-lg-6 {
    padding: 0 1em !important;
  }
  .cam-timestamp {
    margin-bottom: 1vh !important;
  }
}
